import Keycloak from 'keycloak-js'

const VueKeycloak = {
  install(Vue, options = {}) {
    const keycloak = Keycloak(options)
    Vue.keycloak = Vue.prototype.$keycloak = keycloak
  }
}

export default VueKeycloak
