import Vue from 'vue'
import vuetify from './plugins/vuetify'
import App from './App.vue'
import router from './router'
import store from './store'
import './registerServiceWorker'
import i18n from './i18n'
import VueKeycloak from './plugins/keycloak'
import Croppa from 'vue-croppa'
import 'vue-croppa/dist/vue-croppa.css'
import UsailSecurity from 'usail-vue-sdk/plugins/UsailSecurity'
import Api from 'usail-vue-sdk/plugins/Api'
import iri from 'usail-vue-sdk/plugins/IriToId'
import '@/filters/observation'
import VueApexCharts from 'vue-apexcharts'

Vue.use(Api)

Vue.use(UsailSecurity, {
  router: router,
  allowedOrganizationRoles: ['ROLE_SHIPOWNER', 'ROLE_FLEET_MANAGER'],
  allowedVesselRoles: []
})

Vue.use(VueKeycloak, {
  realm: 'usail',
  url: process.env.VUE_APP_IDENTITY_HOST,
  clientId: 'usail-logbook-for-web'
})

Vue.use(Croppa)

Vue.use(iri)

Vue.use(VueApexCharts)
Vue.component('apexchart', VueApexCharts)

Vue.config.productionTip = false

Vue.keycloak
  .init({
    onLoad: 'check-sso',
    silentCheckSsoRedirectUri: window.location.origin + '/silent-check-sso.html',
    promiseType: 'native',
    checkLoginIframe: process.env.NODE_ENV === 'production'
  })
  .then(function(authenticated) {
    if (!authenticated) {
      localStorage.setItem('callbackUrl', window.location.pathname)
    }
    new Vue({
      router,
      store,
      i18n,
      vuetify,
      render: h => h(App)
    }).$mount('#app')
  })
  .catch(function(e) {
    // eslint-disable-next-line no-console
    console.log(e)
    // eslint-disable-next-line no-console
    console.log('failed to initialize')
  })
