import Vue from 'vue'
import Router from 'vue-router'

const Home = () => import('@/views/Home')
const SiteShow = () => import('@/views/Site/Show')
const ObservedPropertyShow = () => import('@/views/ObservedProperty/Show')
const PageNotFound = () => import('usail-vue-sdk/components/error/PageNotFound')
const GenericError = () => import('usail-vue-sdk/components/error/GenericError')
const Forbidden = () => import('@/components/error/Forbidden')

Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'home',
      component: Home
    },
    {
      path: '/site/:id',
      name: 'siteShow',
      component: SiteShow
    },
    {
      path: '/observed-property/:id',
      name: 'observedPropertyShow',
      component: ObservedPropertyShow
    },
    {
      name: 'pageNotFound',
      path: '*',
      component: PageNotFound
    },
    {
      name: 'genericError',
      path: '*',
      component: GenericError
    },
    {
      name: 'forbiddenError',
      path: '*',
      component: Forbidden
    }
  ]
})

const notAuthRoutes = ['logout', 'authCallback', 'pageNotFound', 'genericError', 'forbiddenError', 'start']

router.beforeEach((to, from, next) => {
  if (notAuthRoutes.includes(to.path) || notAuthRoutes.includes(to.name)) {
    next()
    return
  }

  if (
    !Vue.usailSecurity.getUser() ||
    !Vue.keycloak.authenticated ||
    Vue.keycloak.tokenParsed.sub !== Vue.usailSecurity.getUser().sub
  ) {
    Vue.keycloak.login({
      redirectUri: window.origin + '/auth-callback'
    })
  }

  if (Vue.usailSecurity.isAllowed()) {
    next()
  } else {
    next({ name: 'forbiddenError', params: [to.path], replace: true })
  }
})

export function isNoAuthRoute() {
  return notAuthRoutes.includes(router.currentRoute.path) || notAuthRoutes.includes(router.currentRoute.name)
}

export default router
