<template>
  <v-navigation-drawer v-model="drawer" clipped app>
    <v-progress-linear v-if="isLoading" indeterminate color="primary" absolute />
    <v-list v-if="sites" dense nav>
      <v-subheader>{{ $t('message.sites') }}</v-subheader>
      <template>
        <template v-for="site in sites">
          <v-list-item
            :key="site['@id']"
            :value="true"
            color="black"
            :to="{ name: 'siteShow', params: { id: $iriToId(site['@id']) } }"
          >
            <v-list-item-avatar
              v-if="site.image && site.image.contentUrl"
              size="25"
              style="margin-right: 30px !important;"
            >
              <img :src="site.image.contentUrl" :alt="site.name" />
            </v-list-item-avatar>
            <v-list-item-icon v-else>
              <v-icon>{{ site['@type'] === 'Vessel' ? 'mdi-ferry' : 'mdi-satellite-uplink' }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>
                {{ site.name }}
              </v-list-item-title>
            </v-list-item-content>
            <v-list-item-icon v-if="site.observedProperties && site.observedProperties.length">
              <v-chip color="grey lighten-4" small>{{ site.observedProperties.length }}</v-chip>
            </v-list-item-icon>
          </v-list-item>
        </template>
      </template>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
export default {
  name: 'NavigationDrawer',

  data() {
    return {
      sites: [],
      isLoading: false
    }
  },

  computed: {
    drawer: {
      get() {
        return this.$store.state.drawer.status
      },
      set(val) {
        this.$store.commit('drawerSetStatus', val)
      }
    }
  },

  mounted() {
    this.isLoading = true
    this.$api
      .fetch('/sites?pagination=false')
      .then(response => response.json())
      .then(data => {
        this.sites = data['hydra:member']
        this.fetchSites()
      })
      .catch(e => {
        // eslint-disable-next-line no-console
        console.log(e)
      })
  },

  methods: {
    fetchSites() {
      let fetched = 0
      this.sites.forEach((site, index) => {
        this.$api
          .fetch(site['@id'])
          .then(response => response.json())
          .then(data => {
            this.$set(this.sites[index], 'observedProperties', data.observedProperties)
            this.$set(this.sites[index], 'image', data.image)
          })
          .catch(e => {
            // eslint-disable-next-line no-console
            console.log(e)
          })
          .finally(() => {
            fetched++

            if (fetched === this.sites.length) {
              this.isLoading = false
            }
          })
      })
    }
  }
}
</script>
