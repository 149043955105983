<template>
  <v-app>
    <v-main>
      <NavigationDrawer v-if="!isNoAuthRoute" />
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
import NavigationDrawer from '@/components/NavigationDrawer'
import { isNoAuthRoute } from '@/router'
export default {
  name: 'App',

  components: { NavigationDrawer },

  computed: {
    isNoAuthRoute() {
      return isNoAuthRoute(this.$route)
    }
  },

  mounted: function() {
    if ('es' === navigator.language.substr(0, 2)) {
      this.$i18n.locale = 'es'
    } else {
      this.$i18n.locale = 'en'
    }
  }
}
</script>

<style lang="scss">
.row-pointer tbody tr:hover {
  cursor: pointer;
}
.border-right {
  border-right: 1px solid #ddd;
}
</style>
