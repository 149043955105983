import Vue from 'vue'
import Vuex from 'vuex'
import Api from 'usail-vue-sdk/plugins/Api'

import notifications from 'usail-vue-sdk/store/modules/notifications'
import makeCrudModule from 'usail-vue-sdk/store/modules/crud'

Vue.use(Vuex)
Vue.use(Api)

const drawer = {
  state: {
    status: null
  },
  mutations: {
    drawerSetStatus(state, val) {
      state.status = val
    }
  }
}

const store = new Vuex.Store({
  strict: process.env.NODE_ENV !== 'production',
  modules: {
    notifications,
    drawer
  }
})

const apiResources = [
  { name: 'user', path: 'users' },
  { name: 'roleInVessel', path: 'role_in_vessels' },
  { name: 'roleInOrganization', path: 'role_in_organizations' },
  { name: 'site', path: 'sites' },
  { name: 'observedProperty', path: 'observed_properties' },
  { name: 'observation', path: 'observations' }
]

apiResources.forEach(apiResource => {
  store.registerModule(
    apiResource.name,
    makeCrudModule({
      service: Vue.api.makeService(apiResource.path)
    })
  )
})

export default store
