import Vue from 'vue'
import i18n from '@/i18n'

Vue.filter('humanizeResult', function(result, type) {
  switch (type) {
    case 'boolean':
      return Boolean(result)
        .toString()
        .toUpperCase()
    case 'decimal':
      return i18n.n(parseFloat(parseFloat(result).toFixed(2)))
    default:
      return result
  }
})
